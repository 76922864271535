import React from 'react'
import PropTypes from 'prop-types'
import SanityImage from 'gatsby-plugin-sanity-image-alt'

import loadable from '@loadable/component'

import { useSanityImageShape } from '../../helpers/useSanityImageShape'

const Carousel = loadable(() => import('./Carousel'))
const Shape = loadable(() => import('./Shape'))
const BookingSearch = loadable(() => import('../BookingSearch'))

const GalleryMobile = ({ bgImg, gallery }) => {
  let featuredImage
  // Coded by Shane. I don't like it but it works.
  if (bgImg !== null) {
    if (typeof bgImg.asset !== 'undefined') {
      featuredImage = bgImg
    } else {
      featuredImage = gallery.images.find(image => image.featured)
    }
  } else {
    featuredImage = gallery.images.find(image => image.featured)
  }

  return (
    <div className="ratio-mobile-gallery">
      <div className="text-gray-100 inner">
        {bgImg && !gallery && (
          <SanityImage
            {...featuredImage}
            className="absolute inset-0 object-cover h-full"
            alt={featuredImage.alt}
            width={768}
            height={891}
          />
        )}
        {gallery && <Carousel images={gallery?.images} isMobile />}
        <Shape heading />
      </div>
    </div>
  )
}

GalleryMobile.propTypes = {
  gallery: PropTypes.object,
  bgImg: PropTypes.object,
}

GalleryMobile.defaultProps = {
  gallery: {},
  bgImg: null,
}

export default GalleryMobile
